import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { MdBarChart, MdTrendingUp } from "react-icons/md";
import { columnsDataComplex } from "./variables/columnsData";
import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataComplex from "./variables/tableDataComplex.json";
import useResizeObserver from "./useResizeObserver"; // Adjust the path accordingly

const Dashboard = () => {
  const weeklyRevenueRef = useRef(null);
  const totalSpentRef = useRef(null);

  const weeklyRevenueDimensions = useResizeObserver(weeklyRevenueRef);
  const totalSpentDimensions = useResizeObserver(totalSpentRef);

  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>CoinHeatMap Dashboard | Real-Time Crypto Analytics & Insights</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Monitor real-time cryptocurrency analytics with CoinHeatMap's Dashboard. Track top coins by volume, market cap, and social sentiment. Gain insights with our comprehensive charts and tables."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="CoinHeatMap, crypto dashboard, real-time crypto analytics, cryptocurrency volume, market cap, social sentiment, trending coins, blockchain insights, crypto charts, crypto tables"
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="CoinHeatMap Dashboard | Real-Time Crypto Analytics & Insights" />
        <meta
          property="og:description"
          content="Monitor real-time cryptocurrency analytics with CoinHeatMap's Dashboard. Track top coins by volume, market cap, and social sentiment. Gain insights with our comprehensive charts and tables."
        />
        <meta property="og:url" content="https://www.coinheatmap.com/dashboard/admin/default" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="%PUBLIC_URL%/og-dashboard.png" />
        <meta property="og:site_name" content="CoinHeatMap" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="CoinHeatMap Dashboard | Real-Time Crypto Analytics & Insights" />
        <meta
          name="twitter:description"
          content="Monitor real-time cryptocurrency analytics with CoinHeatMap's Dashboard. Track top coins by volume, market cap, and social sentiment. Gain insights with our comprehensive charts and tables."
        />
        <meta name="twitter:url" content="https://www.coinheatmap.com/dashboard/admin/default" />
        <meta name="twitter:image" content="%PUBLIC_URL%/twitter-dashboard.png" />
        <meta name="twitter:site" content="@CoinHeatMap" />

        {/* Canonical URL */}
        <link rel="canonical" href="https://www.coinheatmap.com/dashboard/admin/default" />

        {/* Structured Data (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "CoinHeatMap Dashboard",
            "description": "Monitor real-time cryptocurrency analytics with CoinHeatMap's Dashboard. Track top coins by volume, market cap, and social sentiment. Gain insights with our comprehensive charts and tables.",
            "url": "https://www.coinheatmap.com/dashboard/admin/default",
            "breadcrumb": {
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://www.coinheatmap.com/"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Dashboard",
                  "item": "https://www.coinheatmap.com/dashboard/admin/default"
                }
              ]
            },
            "author": {
              "@type": "Organization",
              "name": "CoinHeatMap",
              "url": "https://www.coinheatmap.com",
              "sameAs": [
                "https://www.linkedin.com/company/103431984/",
                "https://X.com/CoinHeatMap"
              ]
            }
          })}
        </script>
      </Helmet>

      {/* Card Widget */}
      <div className="mt-3 grid grid-cols-1 gap-5">
        <Widget
          icon={<MdTrendingUp className="h-7 w-7" />}
          title={"Top 50 by Volume"}
        />
      </div>

      {/* Weekly Revenue and Total Spent */}
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        {/* Weekly Revenue */}
        <div
          ref={weeklyRevenueRef}
          style={{
            height: "500px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
            padding: "0",
          }}
        >
          <WeeklyRevenue dimensions={weeklyRevenueDimensions} />
        </div>

        {/* Total Spent */}
        <div
          ref={totalSpentRef}
          style={{
            height: "500px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
            padding: "0",
          }}
        >
          <TotalSpent dimensions={totalSpentDimensions} />
        </div>
      </div>

      {/* Main Content Grid */}
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        {/* Check Table - Spans two columns on medium screens and above */}
        <div
          className="md:col-span-2"
          style={{ height: "700px", width: "100%" }}
        >
          <CheckTable /> {/* Top 100 Table */}
        </div>

        {/* Complex Table */}
        <div
          className="md:col-span-2"
          style={{ height: "600px", width: "100%" }}
        >
          <ComplexTable itemsPerPage={20} /> {/* BubbleChart */}
        </div>

        {/* Task Card and Mini Calendar */}
        <div className="md:col-span-2 h-full grid grid-cols-1 gap-5 md:grid-cols-2">
          <TaskCard /> {/* Real-Time Metrics */}
          <MiniCalendar /> {/* Exchange Metrics */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;