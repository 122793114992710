import React from 'react';
import { Helmet } from 'react-helmet';
import CheckTable from "../default/components/CheckTable.jsx";

const Top100 = () => {
  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>Top 100 Cryptocurrencies | Real-Time Market Data & Social Insights</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Explore the Top 100 cryptocurrencies with real-time market data, including price, market cap, trading volume, and social sentiment. Our platform aggregates data from various social media sources to provide comprehensive and interactive crypto tables."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Top 100 cryptocurrencies, crypto market data, real-time crypto prices, cryptocurrency rankings, market capitalization, trading volume, social sentiment, crypto analytics, blockchain insights"
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Top 100 Cryptocurrencies | Real-Time Market Data & Social Insights" />
        <meta
          property="og:description"
          content="Explore the Top 100 cryptocurrencies with real-time market data, including price, market cap, trading volume, and social sentiment. Our platform aggregates data from various social media sources to provide comprehensive and interactive crypto tables."
        />
        <meta property="og:url" content="http://coinheatmap.com/dashboard/admin/coin-tables" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CoinHeatMap" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Top 100 Cryptocurrencies | Real-Time Market Data & Social Insights" />
        <meta
          name="twitter:description"
          content="Explore the Top 100 cryptocurrencies with real-time market data, including price, market cap, trading volume, and social sentiment. Our platform aggregates data from various social media sources to provide comprehensive and interactive crypto tables."
        />
        <meta name="twitter:url" content="http://coinheatmap.com/dashboard/admin/coin-tables" />
        <meta name="twitter:site" content="@CoinHeatMap" />

        {/* Canonical URL */}
        <link rel="canonical" href="http://coinheatmap.com/dashboard/admin/coin-tables" />

        {/* Structured Data (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Top 100 Cryptocurrencies",
            "description": "Explore the Top 100 cryptocurrencies with real-time market data, including price, market cap, trading volume, and social sentiment. Our platform aggregates data from various social media sources to provide comprehensive and interactive crypto tables.",
            "url": "http://coinheatmap.com/dashboard/admin/coin-tables",
            "breadcrumb": {
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "http://coinheatmap.com/"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Dashboard",
                  "item": "http://coinheatmap.com/dashboard"
                },
                {
                  "@type": "ListItem",
                  "position": 3,
                  "name": "Coin Tables",
                  "item": "http://coinheatmap.com/dashboard/admin/coin-tables"
                }
              ]
            },
            "author": {
              "@type": "Organization",
              "name": "CoinHeatMap",
              "url": "http://coinheatmap.com",
              "sameAs": [
                "https://www.linkedin.com/company/103431984/",
                "https://X.com/CoinHeatMap"
              ]
            },
            "mainEntity": {
              "@type": "Table",
              "name": "Top 100 Cryptocurrencies",
              "description": "A comprehensive table displaying the top 100 cryptocurrencies by market data and social insights."
            }
          })}
        </script>
      </Helmet>

      {/* Top 100 Cryptocurrencies Page Content */}
      <div className="flex flex-col w-full h-full p-4">
        <h1 className="text-2xl font-semibold mb-4">Top Cryptocurrencies</h1>
        <div className="flex-1 w-full overflow-auto">
          <CheckTable /> {/* Your existing CheckTable component */}
        </div>
      </div>
    </>
  );
};

export default Top100;
