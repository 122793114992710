// admin layout:

import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import Courses from "views/admin/courses"; // Import the Courses component

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(() => window.innerWidth >= 1200);
  const [currentRoute, setCurrentRoute] = React.useState("Social Influence");

  // Handle window resize to toggle sidebar
  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Call handler once to set the initial state correctly
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Close sidebar on route change for small screens
  React.useEffect(() => {
    if (window.innerWidth < 1200) {
      setOpen(false);
    }
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Social Influence";

    // Check if we're on a /coins/:coinID route and extract coinID if so
    const coinIDMatch = location.pathname.match(/^\/admin\/coins\/([^/]+)$/);
    if (coinIDMatch) {
      const coinID = coinIDMatch[1];
      setCurrentRoute(coinID); // Set currentRoute to the coinID directly
      return coinID;
    }
    if (location.pathname.startsWith('/admin/hub/')) {
      setCurrentRoute("Hub"); // Set currentRoute to "Hub" for blog posts
      return "Hub";
    }

    // Otherwise, find the matching route based on the path
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.includes(`${routes[i].layout}/${routes[i].path}`)) {
        setCurrentRoute(routes[i].name);
        return routes[i].name;
      }
    }

    setCurrentRoute(activeRoute); // Fallback to default if no match
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.includes(`${routes[i].layout}/${routes[i].path}`)) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        const Component = prop.component;
        return (
          <Route
            path={`/${prop.path}`}
            element={<Component />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  // Optional: Set the text direction if needed
  document.documentElement.dir = "ltr";

  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"CoinHeatMap"}
              brandText={currentRoute} // Dynamically set to coinID or route name
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}
                {/* Add the specific route for blog posts */}
                <Route path="/hub/:slug" element={<Courses />} />
                <Route path="/hub" element={<Courses />} />
                <Route
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}