// App.js
import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4"; // Import react-ga4

import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    const basename = "/dashboard";
    let pagePath = location.pathname + location.search;

    // Remove basename from the path if present
    if (pagePath.startsWith(basename)) {
      pagePath = pagePath.slice(basename.length);
    }

    // Ensure the path starts with a '/'
    if (!pagePath.startsWith("/")) {
      pagePath = "/" + pagePath;
    }

    // Send pageview only in production
    if (process.env.NODE_ENV === "production") {
      ReactGA.send({ hitType: "pageview", page: pagePath });
      console.log(`GA Pageview sent: ${pagePath}`);
    }
  }, [location]);

  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="rtl/*" element={<RtlLayout />} />
      
      <Route path="/" element={<Navigate to="/admin" replace />} />
    </Routes>
  );
};

export default App;