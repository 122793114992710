// src/ProfileOverview.jsx
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet
import Banner from "./components/Banner";
import General from "./components/General";
import Notification from "./components/Notification";
import Project from "./components/Project";
import Storage from "./components/Storage";
import Upload from "./components/Upload";
import axios from "axios";

const ProfileOverview = () => {
  const { coinID } = useParams();
  const [coinData, setCoinData] = useState(null);
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null); // For error handling

  console.log("coinID:", coinID);

  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to "bitcoin" if coinID is set to ":coinID"
    if (coinID === ":coinID") {
      navigate("/admin/coins/bitcoin", { replace: true });
    }
  }, [coinID, navigate]);

  useEffect(() => {
    const fetchCoinData = async () => {
      try {
        setLoading(true); // Start loading

        const id = coinID === ':coinID' ? 'bitcoin' : coinID;

        // Use the correct API endpoint
        const response = await axios.get(`/api/crypto-data/?identity=${id}`);

        // Log the full response data
        console.log("Full response data:", response.data);

        // Assuming data.cryptos is an array with one coin object
        if (response.data.cryptos && response.data.cryptos.length > 0) {
          setCoinData(response.data.cryptos[0]);
          console.log("Fetched coin data:", response.data.cryptos[0]); // Log the coin data
        } else {
          setError("Coin not found");
        }
      } catch (error) {
        console.error("Error fetching coin data:", error);

        if (error.response) {
          // The request was made and the server responded with a status code outside of 2xx
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          setError(`Error fetching data: ${error.response.status}`);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request);
          setError("No response from server");
        } else {
          // Something happened in setting up the request
          console.error("Error setting up request:", error.message);
          setError(error.message);
        }
      } finally {
        setLoading(false); // Loading finished
      }
    };

    fetchCoinData();
  }, [coinID]);

  if (loading) {
    return <div>Loading...</div>; // Or a spinner component
  }

  if (error) {
    return <div>Error: {error}</div>; // Display error message
  }

  return (
    <div className="flex w-full flex-col gap-5">
      <Helmet>
        {/* Title Tag */}
        <title>{`${coinData.identity} Price Today, ${coinData.symbol} to USD Live Price, Market Cap & Chart | CoinHeatMap`}</title>

        {/* Meta Description */}
        <meta
          name="description"
          content={`The live ${coinData.name} price today is $${coinData.current_price} USD with a 24-hour trading volume of $${coinData.total_volume} USD. We update our ${coinData.symbol} to USD price in real-time.`}
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:url" content={`https://coinheatmap.com/dashboard/admin/coins/${coinData.identity}`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${coinData.name} Price Today, ${coinData.symbol} to USD Live Price, Market Cap & Chart | CoinHeatMap`}
        />
        <meta
          property="og:description"
          content={`The live ${coinData.name} price today is $${coinData.current_price} USD with a 24-hour trading volume of $${coinData.total_volume} USD. We update our ${coinData.symbol} to USD price in real-time.`}
        />
        {/* Omit og:image as per user request */}

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`https://coinheatmap.com/dashboard/admin/coins/${coinData.identity}`} />
        <meta
          name="twitter:title"
          content={`${coinData.name} Price Today, ${coinData.symbol} to USD Live Price, Market Cap & Chart | CoinHeatMap`}
        />
        <meta
          name="twitter:description"
          content={`The live ${coinData.name} price today is $${coinData.current_price} USD with a 24-hour trading volume of $${coinData.total_volume} USD. We update our ${coinData.symbol} to USD price in real-time.`}
        />
        {/* Omit twitter:image as per user request */}
        <meta name="twitter:site" content="@CoinHeatMap" />

        {/* Canonical URL */}
        <link rel="canonical" href={`https://coinheatmap.com/dashboard/admin/coins/${coinData.identity}`} />

        {/* Structured Data (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "CoinHeatMap",
            "url": `https://coinheatmap.com/dashboard/admin/coins/${coinData.identity}`,
            "description": `Discover trending crypto coins with CoinHeatMap's real-time social volume heatmap. Swap tokens seamlessly across EVM chains using 1inch. Access our insightful crypto blogs and engage with our use-to-earn model with $HMAP tokens.`,
            "sameAs": [
              "https://www.linkedin.com/company/103431984/",
              "https://X.com/CoinHeatMap"
            ],
            "potentialAction": {
              "@type": "SearchAction",
              "target": `https://coinheatmap.com/dashboard/admin/coins/${coinData.identity}/search?q={search_term_string}`,
              "query-input": "required name=search_term_string"
            }
          })}
        </script>
      </Helmet>

      {/* First Grid: Banner, Storage, Upload */}
      <div className="w-full mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
          <Banner coinData={coinData} />
        </div>

        <div className="col-span-3 lg:!mb-0">
          <Storage coinData={coinData} />
        </div>

        <div className="z-0 col-span-5 lg:!mb-0">
          <Upload coinData={coinData} />
        </div>
      </div>

      {/* Second Grid: Project and General */}
      <div className="grid h-full grid-cols-1 gap-5 lg:grid-cols-12">
        {/* Project Component */}
        <div className="col-span-12 lg:col-span-4 lg:mb-0 3xl:col-span-4">
          <Project coinData={coinData} />
        </div>

        {/* General Component */}
        <div className="col-span-12 h-[50vh] lg:col-span-8 lg:mb-0 3xl:col-span-8">
          <General coinData={coinData} />
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;
