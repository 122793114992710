// src/components/calendar/MiniCalendar.jsx
import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "assets/css/MiniCalendar.css";

const MiniCalendar = ({
  blogs = [],
  onDateSelect,
  value, // Controlled by parent
}) => {
  const maxDate = new Date(2025, 0, 31);

  const handleChange = (date) => {
    if (date > maxDate) return;
    if (onDateSelect) {
      onDateSelect(date);
    }
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const hasBlog = blogs.some((b) => {
        const bDate = new Date(b.created_date);
        return (
          bDate.getFullYear() === date.getFullYear() &&
          bDate.getMonth() === date.getMonth() &&
          bDate.getDate() === date.getDate()
        );
      });

      if (!hasBlog && date <= maxDate) {
        return "no-blog-day"; // Style for dates without blogs
      }
    }
    return null;
  };

  return (
    <div>
      <p className="mb-2 text-xl font-medium text-navy-700 dark:text-white">
        Browse Blogs by Date:
      </p>
      <div
        className={`flex w-full md:w-[320px] h-full max-w-full flex-col
          rounded-[20px] bg-white px-3 py-4 dark:border
          dark:border-white/10 dark:bg-navy-800`}
      >
        <Calendar
          onChange={handleChange}
          value={value} // Controlled by parent
          prevLabel={<MdChevronLeft className="ml-1 h-6 w-6" />}
          nextLabel={<MdChevronRight className="ml-1 h-6 w-6" />}
          view="month"
          maxDate={maxDate}
          maxDetail="month"
          tileClassName={tileClassName}
        />
      </div>
    </div>
  );
};

export default MiniCalendar;
