// Heatmaps.js
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import WeeklyRevenue from "../default/components/WeeklyRevenue.jsx";
import TotalSpent from "../default/components/TotalSpent.jsx";

const Heatmaps = () => {
  const weeklyRevenueRef = useRef(null);
  const totalSpentRef = useRef(null);

  const [weeklyRevenueDimensions, setWeeklyRevenueDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [totalSpentDimensions, setTotalSpentDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [itemsPerPage, setItemsPerPage] = useState(20); // Default items per page

  useEffect(() => {
    const handleResize = () => {
      if (weeklyRevenueRef.current) {
        const { width, height } = weeklyRevenueRef.current.getBoundingClientRect();
        setWeeklyRevenueDimensions({ width, height });
      }
      if (totalSpentRef.current) {
        const { width, height } = totalSpentRef.current.getBoundingClientRect();
        setTotalSpentDimensions({ width, height });
      }
    };

    // Initial measurement
    handleResize();

    // Update dimensions on window resize
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        {/* Basic Meta Tags */}
        <title>Cryptocurrency Heatmaps | CoinHeatMap</title>
        <meta
          name="description"
          content="Explore the first social volume heatmap alongside dynamic cryptocurrency heatmaps displaying market capitalization and trading volumes for Bitcoin, Ethereum, XRP, and more. Stay updated with real-time data to make informed trading decisions."
        />
        <link rel="canonical" href="https://www.coinheatmap.com/dashboard/admin/heatmaps" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Cryptocurrency Heatmaps | CoinHeatMap" />
        <meta
          property="og:description"
          content="Discover the first social volume heatmap along with dynamic cryptocurrency heatmaps showcasing market capitalization and trading volumes for Bitcoin, Ethereum, XRP, and more. Leverage real-time data to enhance your trading strategies."
        />
        <meta property="og:url" content="https://www.coinheatmap.com/dashboard/admin/heatmaps" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CoinHeatMap" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Cryptocurrency Heatmaps | CoinHeatMap" />
        <meta
          name="twitter:description"
          content="Discover the first social volume heatmap along with dynamic cryptocurrency heatmaps showcasing market capitalization and trading volumes for Bitcoin, Ethereum, XRP, and more. Leverage real-time data to enhance your trading strategies."
        />
        <meta name="twitter:site" content="@CoinHeatMap" />

        {/* Hreflang Tags for Multilingual Support */}
        <link rel="alternate" hreflang="en" href="https://www.coinheatmap.com/dashboard/admin/heatmaps" />
        {/* Add more hreflang tags if you support multiple languages */}

        {/* Responsive Meta Tag */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Theme Color for Mobile Browsers */}
        <meta name="theme-color" content="#ffffff" />
      </Helmet>

      <div className="flex flex-col h-screen">
        {/* WeeklyRevenue Component */}
        <div ref={weeklyRevenueRef} className="flex-1 max-h-[70vh]">
          <WeeklyRevenue
            dimensions={weeklyRevenueDimensions}
            itemsPerPage={itemsPerPage}
          />
        </div>

        {/* TotalSpent Component */}
        <div ref={totalSpentRef} className="flex-1 max-h-[70vh]">
          <TotalSpent
            dimensions={totalSpentDimensions}
            itemsPerPage={itemsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default Heatmaps;
