// src/views/admin/courses/index.jsx
import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { MdOutlineUpgrade, MdClose } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import DOMPurify from "dompurify"; // Import DOMPurify for sanitization

import Course from "components/card/Course";
import HubCalendar from "components/calendar/HubCalendar";
import MiniCalendar from "components/calendar/MiniCalendar";
import Card from "components/card";

export default function Courses() {
  // Define default tabs
  const defaultTabs = [
    { id: 1, title: "Latest Insights", type: "latest" },
    { id: 2, title: "Past Insights", type: "past" },
  ];

  const [tabs, setTabs] = useState(defaultTabs); // State to manage all tabs
  const [activeTabId, setActiveTabId] = useState(1); // ID of the currently active tab
  const [blogs, setBlogs] = useState([]); // Blogs to display based on active tab
  const [filteredBlogs, setFilteredBlogs] = useState([]); // Filtered blogs
  const [latestDate, setLatestDate] = useState(null); // Latest blog date
  const [currentDate, setCurrentDate] = useState(null); // Current selected date
  const [loading, setLoading] = useState(false); // Loading state
  const { slug: urlSlug } = useParams(); // URL parameter for direct navigation
  const navigate = useNavigate();
  const fetchControllerRef = useRef(null); // Ref for aborting fetch requests
  const [cache, setCache] = useState({}); // Cache to store fetched blogs
  const blogRef = useRef(null); // Ref for scrolling to a blog
  const [warningMessage, setWarningMessage] = useState("");


  // Define the ID for "Past Blogs" tab for easier reference
  const PAST_BLOGS_TAB_ID = 2;

  // Function to format Date objects to YYYY-MM-DD
  const formatDate = (dateObj) => {
    if (!(dateObj instanceof Date) || isNaN(dateObj)) {
      console.error("Invalid date object:", dateObj);
      return null;
    }
    const yyyy = dateObj.getFullYear();
    const mm = String(dateObj.getMonth() + 1).padStart(2, "0");
    const dd = String(dateObj.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  // Fetch blogs by date
  const fetchByDate = useCallback(
    async (dateObj, setLoadingState = true) => {
      const dateStr = formatDate(dateObj);

      if (!dateStr) {
        console.error("Invalid date string:", dateStr);
        return;
      }

      console.log("Fetching blogs for date:", dateStr);

      if (setLoadingState) setLoading(true);
      setFilteredBlogs([]);

      if (cache[dateStr]) {
        console.log("Fetching from cache for:", dateStr);
        setBlogs(cache[dateStr]);
        setFilteredBlogs(cache[dateStr]);
        if (setLoadingState) setLoading(false);
        return;
      }

      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
      }
      fetchControllerRef.current = new AbortController();

      try {
        const res = await axios.get(`/api/blogs-by-date/?date=${dateStr}`, {
          signal: fetchControllerRef.current.signal,
        });
        const { latest_date, blogs: dayBlogs } = res.data || {};

        // Do NOT update latestDate here
        // Only update currentDate
        setCurrentDate(latest_date ? new Date(latest_date) : null);
        setCache((prev) => ({ ...prev, [dateStr]: dayBlogs }));
        setBlogs(dayBlogs);
        setFilteredBlogs(dayBlogs);
        console.log("Successfully fetched blogs for:", dateStr, dayBlogs);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Fetch cancelled:", dateStr);
        } else {
          console.error("Error fetching blogs for:", dateStr, error);
          setBlogs([]);
          setFilteredBlogs([]);
        }
      } finally {
        if (setLoadingState) setLoading(false);
      }
    },
    [cache]
  );

  // Fetch blogs by slug (for direct navigation or dynamic tabs)
  const fetchBySlug = useCallback(
    async (slug) => {
      setLoading(true);
      try {
        const res = await axios.get(`/api/blogs-by-date/?slug=${slug}`);
        const { latest_date, blog } = res.data || {};

        if (latest_date) {
          setLatestDate(new Date(latest_date));
          setCurrentDate(new Date(latest_date));
        }

        if (blog) {
          setBlogs([blog]);
          setFilteredBlogs([blog]);
          return blog;
        } else {
          console.error("Blog not found for slug:", slug);
          return null;
        }
      } catch (error) {
        console.error("Error fetching blog by slug:", error);
        return null;
      } finally {
        setLoading(false);
      }
    },
    []
  );

  // Initialize blogs on component mount
  useEffect(() => {
    const initBlogs = async () => {
      setLoading(true);
      try {
        let res;
        if (urlSlug) {
          // Direct navigation to a specific blog
          const blog = await fetchBySlug(urlSlug);
          if (blog) {
            // Remove any existing blog tabs to ensure only one blog tab is open
            setTabs((prevTabs) =>
              prevTabs.filter((tab) => tab.type !== "blog")
            );

            // Add a new dynamic tab for the blog
            const newTab = {
              id: Date.now(), // Unique ID
              title: blog.title,
              type: "blog",
              slug: blog.slug,
            };
            setTabs((prevTabs) => [...prevTabs, newTab]);
            setActiveTabId(newTab.id); // Set the new tab as active
          }
        } else {
          // Fetch latest blogs
          res = await axios.get("/api/blogs-by-date/");
          const { latest_date, latest_blogs } = res.data || {};

          let initialLatest = null;

          if (latest_date) {
            initialLatest = new Date(latest_date);
          } else if (latest_blogs && latest_blogs.length > 0) {
            // Derive latest_date from the most recent created_date in latest_blogs
            initialLatest = latest_blogs.reduce((latest, blog) => {
              const blogDate = new Date(blog.created_date);
              return blogDate > latest ? blogDate : latest;
            }, new Date(latest_blogs[0].created_date));
          }

          setLatestDate(initialLatest);
          setCurrentDate(initialLatest); // Initialize currentDate
          setBlogs(latest_blogs);
          setFilteredBlogs(latest_blogs);
          console.log("Initialized blogs with latest_date:", initialLatest, latest_blogs);
        }
      } catch (err) {
        console.error("Error initializing blogs:", err);
        setBlogs([]);
        setFilteredBlogs([]);
      } finally {
        setLoading(false);
      }
    };

    initBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSlug]); // Run only when urlSlug changes

  // Handle tab toggling
  const handleTabToggle = useCallback(
    async (tab) => {
      if (tab.type === "blog") {
        setActiveTabId(tab.id);
        // Fetch and display the specific blog
        const blog = await fetchBySlug(tab.slug);
        if (blog) {
          setBlogs([blog]);
          setFilteredBlogs([blog]);
          // Scroll to the blog
          setTimeout(() => {
            if (blogRef.current) {
              blogRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
          }, 100);
        }
      } else {
        // If switching to a default tab, remove all dynamic blog tabs
        setTabs((prevTabs) => prevTabs.filter((t) => t.type !== "blog"));
        setActiveTabId(tab.id);
        navigate("/admin/hub/", { replace: true });

        // Fetch the blogs for the selected default tab
        if (tab.type === "latest") {
          if (latestDate) {
            await fetchByDate(latestDate);
          }
        } else if (tab.type === "past") {
          if (latestDate) {
            const pastDate = new Date(latestDate);
            pastDate.setDate(pastDate.getDate() - 1);
            await fetchByDate(pastDate);
          }
        }
      }
    },
    [fetchBySlug, fetchByDate, latestDate, navigate]
  );

  const handleDateSelect = useCallback(
    async (selectedDate) => {
      console.log("handleDateSelect: Selected date:", selectedDate);
      if (!(selectedDate instanceof Date) || isNaN(selectedDate)) {
        console.error("Invalid selected date in handleDateSelect:", selectedDate);
        return;
      }
  
      // Check if the selected date is after the latestDate
      if (latestDate && selectedDate > latestDate) {
        // Format the latestDate for display (e.g., "January 1, 2025")
        const formattedLatestDate = latestDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
  
        // Set the warning message
        setWarningMessage(`Latest Blogs are for ${formattedLatestDate}`);
  
        // Automatically clear the warning after 3 seconds
        setTimeout(() => setWarningMessage(""), 3000);
  
        return; // Prevent fetching blogs for a future date
      }
  
      // Check if the selected date is the latest date
      if (
        latestDate &&
        selectedDate.getFullYear() === latestDate.getFullYear() &&
        selectedDate.getMonth() === latestDate.getMonth() &&
        selectedDate.getDate() === latestDate.getDate()
      ) {
        // Activate the "Latest Blogs" tab
        const latestTab = tabs.find((tab) => tab.type === "latest");
        if (latestTab) {
          handleTabToggle(latestTab);
        }
        return;
      }
  
      // Otherwise, fetch blogs for the selected date and display them
      // **Enhancement: Highlight the "Past Blogs" tab**
      setActiveTabId(PAST_BLOGS_TAB_ID); // Set "Past Blogs" tab as active
      setBlogs([]);
      setFilteredBlogs([]);
      setLoading(true);
      await fetchByDate(selectedDate);
      setLoading(false);
    },
    [latestDate, tabs, handleTabToggle, fetchByDate]
  );
  

  // Handle expanding a blog (adding a dynamic tab)
  const handleExpandBlog = async (slug) => {
    // Remove any existing blog tabs to ensure only one blog tab is open
    setTabs((prevTabs) => prevTabs.filter((tab) => tab.type !== "blog"));

    // Fetch blog data
    const blog = await fetchBySlug(slug);
    if (blog) {
      // Add a new dynamic tab
      const newTab = {
        id: Date.now(), // Unique ID based on timestamp
        title: blog.title,
        type: "blog",
        slug: blog.slug,
      };
      setTabs((prevTabs) => [...prevTabs, newTab]);
      setActiveTabId(newTab.id); // Activate the new tab
      navigate(`/admin/hub/${slug}`, { replace: true });
    }
  };

  // Handle closing a dynamic tab
  const handleCloseTab = (tabId) => {
    setTabs((prevTabs) => prevTabs.filter((tab) => tab.id !== tabId));
    if (activeTabId === tabId) {
      // If the closed tab was active, switch to the first default tab
      const firstDefaultTab = defaultTabs[0];
      setActiveTabId(firstDefaultTab.id);
      navigate("/admin/hub/", { replace: true });
    }
  };

  // Effect to handle scrolling to the expanded blog
  useEffect(() => {
    if (activeTabId && tabs.find((tab) => tab.id === activeTabId && tab.type === "blog")) {
      setTimeout(() => {
        if (blogRef.current) {
          blogRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 100);
    }
  }, [activeTabId, tabs]);

  // Determine if the active tab is a blog
  const activeTab = tabs.find((tab) => tab.id === activeTabId);
  const isBlogActive = activeTab && activeTab.type === "blog";
  const activeBlog = isBlogActive ? blogs[0] : null;

  // Generate SEO-related variables
  let helmetTitle = "AI Driven Insights Blog - CoinHeatMap";
  let helmetDescription =
    "Stay ahead with CoinHeatMap's AI-driven insights into cryptocurrency. Get the latest news, market trends, and expert analyses to make informed trading decisions.";
  let canonicalUrl = "https://www.coinheatmap.com/dashboard/admin/hub/";
  let keywords = "AI insights, cryptocurrency, latest news, crypto trends, market analysis, Bitcoin, Ethereum, blockchain";

  if (isBlogActive && activeBlog) {
    helmetTitle = `${activeBlog.title} | CoinHeatMap`;
    const combinedTopics = [activeBlog.main_topic, ...activeBlog.tags].filter(Boolean).join(", ");
    helmetDescription = `${DOMPurify.sanitize(activeBlog.description.replace(/<[^>]+>/g, "")).substring(0, 150)} Discover insights on ${combinedTopics}.`;
    canonicalUrl = `https://www.coinheatmap.com/dashboard/admin/hub/${activeBlog.slug}`;
    keywords = combinedTopics;
  }

  return (
    <>
      <Helmet>
        {/* Basic Meta Tags */}
        <title>{helmetTitle}</title>
        <meta name="description" content={helmetDescription} />
        <link rel="canonical" href={canonicalUrl} />

        {/* Open Graph Tags */}
        <meta property="og:title" content={helmetTitle} />
        <meta property="og:description" content={helmetDescription} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content={isBlogActive ? "article" : "website"} />
        <meta property="og:site_name" content="CoinHeatMap" />
        {/* Exclude og:image as per instruction */}
        {/* {isBlogActive && activeBlog && activeBlog.imageUrl && (
          <meta property="og:image" content={activeBlog.imageUrl} />
        )} */}

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={helmetTitle} />
        <meta name="twitter:description" content={helmetDescription} />
        <meta name="twitter:site" content="@CoinHeatMap" />
        {/* Exclude twitter:image as per instruction */}
        {/* {isBlogActive && activeBlog && activeBlog.imageUrl && (
          <meta name="twitter:image" content={activeBlog.imageUrl} />
        )} */}

        {/* Structured Data (JSON-LD) */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "${isBlogActive && activeBlog ? "Article" : "WebPage"}",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${canonicalUrl}"
              },
              "headline": "${helmetTitle.replace(/"/g, '\\"')}",
              "description": "${helmetDescription.replace(/"/g, '\\"')}",
              "keywords": "${keywords.replace(/"/g, '\\"')}",
              "author": {
                "@type": "Organization",
                "name": "CoinHeatMap"
              },
              "publisher": {
                "@type": "Organization",
                "name": "CoinHeatMap",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://pbs.twimg.com/profile_images/1855785309151121408/b2pyqI7__400x400.jpg"
                }
              },
              "datePublished": "${isBlogActive && activeBlog ? new Date(activeBlog.created_date).toISOString() : new Date().toISOString()}",
              "dateModified": "${isBlogActive && activeBlog ? new Date(activeBlog.created_date).toISOString() : new Date().toISOString()}"
              ${isBlogActive && activeBlog && activeBlog.imageUrl ? `,
              "image": "${activeBlog.imageUrl}"` : ""}
            }
          `}
        </script>
      </Helmet>

      <main className="relative mt-3 flex h-auto w-full font-dm md:gap-7 lg:flex-row">
        <section className="w-full rounded-[20px]" aria-labelledby="blogs-heading">
          {/* TABS */}
          <div
            className="mb-4 mt-2 flex w-full flex-col items-center rounded-xl bg-white/70 pl-[45px]
                shadow-2xl shadow-white dark:!bg-navy-900 dark:shadow-none xl:mb-3"
          >
            <div className="flex w-full justify-start gap-4 overflow-x-auto text-3xl">
              {tabs.map((tab) => (
                <div
                  key={tab.id}
                  className={`flex items-center gap-2 border-b-4 pb-3 cursor-pointer ${
                    activeTabId === tab.id
                      ? "border-brand-500 dark:border-brand-400"
                      : "border-transparent hover:border-gray-300 dark:hover:border-navy-700"
                  }`}
                  onClick={() => handleTabToggle(tab)}
                >
                  <h2 className="text-base font-medium text-navy-700 dark:text-white whitespace-nowrap">
                    {tab.title}
                  </h2>
                  {/* Show close icon only for dynamic tabs */}
                  {tab.type === "blog" && (
                    <MdClose
                      className="text-red-500 hover:text-red-700"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the tab click
                        handleCloseTab(tab.id);
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* BLOG LIST */}
          <div className="flex w-full flex-col">
            {loading ? (
              <div className="flex h-32 items-center justify-center">
                {/* Spinner */}
                <div className="h-8 w-8 rounded-full border-4 border-blue-400 border-t-transparent animate-spin"></div>
                <p className="ml-2 text-gray-500 dark:text-white">Loading blogs...</p>
              </div>
            ) : filteredBlogs.length > 0 ? (
              filteredBlogs.map((blog) => (
                <article
                  key={blog.id}
                  className="mb-5"
                  aria-labelledby={`blog-${blog.id}-title`}
                  ref={
                    isBlogActive && activeBlog && activeBlog.slug === blog.slug
                      ? blogRef
                      : null
                  }
                >
                  <Course
                    extra=""
                    bgBox="bg-gradient-to-br from-[#7A64FF] via-[#FF508B] to-[#FD6D53]"
                    icon={<MdOutlineUpgrade />}
                    title={blog.title}
                    day={new Date(blog.created_date).toLocaleString("en-US", {
                      weekday: "short",
                    })}
                    date={new Date(blog.created_date).toLocaleDateString("en-US")}
                    topics={blog.tags}
                    mainTopic={blog.main_topic}
                    imageUrl={blog.imageUrl}
                    description={blog.description}
                    slug={blog.slug}
                    isExpanded={
                      isBlogActive && activeBlog && activeBlog.slug === blog.slug
                    }
                    onExpand={() => handleExpandBlog(blog.slug)}
                  />
                </article>
              ))
            ) : (
              <p className="mt-4 text-center text-gray-500 dark:text-white">
                No blogs found.
              </p>
            )}
          </div>
        </section>

        <div className="h-0 w-0 bg-gray-300 dark:!bg-navy-700 lg:h-[1050px] lg:w-px" />

        <aside
          className="mt-1 flex h-full w-full flex-col items-center rounded-[20px] bg-white px-4 py-4
              shadow-2xl shadow-gray-100 dark:!bg-navy-800 dark:shadow-none lg:w-[275px] 3xl:w-[470px]"
          aria-label="Sidebar"
        >
          <Card extra="max-w-full">
            <HubCalendar
              blogs={blogs}
              onDateSelect={handleDateSelect}
              value={currentDate} // Pass currentDate as value
            />
          </Card>
          <Card extra="w-full mt-4">
            <MiniCalendar
              blogs={blogs}
              onDateSelect={handleDateSelect}
              value={currentDate} // Pass currentDate as value
            />
          </Card>
        </aside>
      </main>
    </>
  );
}
