// src/components/card/Course.jsx
import React, { useState } from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify"; // Import DOMPurify for sanitization

const Course = ({
  bgBox,
  icon,
  title,
  day,
  date,
  mainTopic,
  topics = [],
  extra,
  imageUrl,
  description,
  slug,
  isExpanded, // Prop to indicate if this course is expanded
  onExpand,   // Prop for the expand handler
}) => {
  const [imgError, setImgError] = useState(false);

  // Sanitize the description to prevent XSS attacks
  const sanitizedDescription = DOMPurify.sanitize(description || "No content available.");

  return (
    <div className={`relative w-full ${extra}`}>
      {/* Inline styles to force headings within blog content to be bold */}
      <style>
        {`
          .blog-content h1,
          .blog-content h2,
          .blog-content h3,
          .blog-content h4,
          .blog-content h5,
          .blog-content h6 {
            font-weight: bold;
          }
        `}
      </style>

      {/* Main card container */}
      <div
        onClick={onExpand} // Use the onExpand handler from props
        className="cursor-pointer flex h-fit w-full flex-col
                   rounded-[20px] bg-white bg-clip-border p-4 !pb-10
                   shadow-3xl shadow-shadow-500 dark:bg-navy-800
                   dark:shadow-none 2xl:flex-row 3xl:h-[310px]"
        role="button"
        tabIndex={0}
        aria-expanded={isExpanded}
        onKeyPress={(e) => { if (e.key === 'Enter') onExpand(); }}
      >
        {/* Image or Icon */}
        <div
          className={`${bgBox} mr-8 flex min-h-[200px] min-w-full
                      items-center justify-center rounded-xl
                      text-[100px] text-white
                      2xl:h-[270px] 2xl:min-w-[270px]`}
        >
          {imageUrl && !imgError ? (
            <img
              src={imageUrl}
              alt={title} // Removed redundant "Image for"
              className="h-full w-full rounded-xl object-cover"
              onError={() => setImgError(true)}
              loading="lazy"
            />
          ) : (
            icon
          )}
        </div>

        {/* Text container */}
        <div className="3xl:ml-8 mt-6 flex w-full flex-col justify-between pt-0 md:pt-6">
          <div>
            {/* Title + Date */}
            <div className="flex flex-col items-start justify-between
                            2xl:flex-row 2xl:items-center">
              <h2 className="font-bold leading-7 text-navy-700 dark:text-white
                             md:text-[18px] 3xl:text-[28px] 3xl:leading-10">
                {title}
              </h2>
              <div className="mt-2 flex items-center gap-2 font-medium 2xl:mt-0">
                <p className="text-gray-600 dark:text-white">{day} </p>
                <p className="text-base text-navy-700 dark:text-white"> {date}</p>
              </div>
            </div>
          </div>

          {/* Topics & Main Topic */}
          <div className="mt-4 flex flex-wrap items-center gap-2 md:flex-row">
            {Array.isArray(topics) &&
              topics.map((topic, index) => (
                <div
                  key={index}
                  className="flex h-7 items-center justify-center
                             rounded-lg bg-lightPrimary px-2 text-xs
                             font-bold uppercase tracking-wide
                             text-brand-500 dark:bg-white/5
                             dark:text-white"
                >
                  {topic}
                </div>
              ))}
            {mainTopic && (
              <div className="2xl:ml-auto flex items-center gap-1
                              text-sm font-medium text-navy-700
                              dark:text-white">
                <span>{mainTopic}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Expanded content (white background, large font) */}
      {isExpanded && (
        <div
          className="mt-2 rounded-xl bg-white p-4 text-lg leading-relaxed text-navy-700 dark:text-white"
        >
          <div
            className="blog-content"
            dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
          />
        </div>
      )}
    </div>
  );
};

Course.propTypes = {
  bgBox: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  mainTopic: PropTypes.string,
  topics: PropTypes.arrayOf(PropTypes.string),
  extra: PropTypes.string,
  imageUrl: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired, // Prop to indicate if this course is expanded
  onExpand: PropTypes.func.isRequired,   // Prop for the expand handler
};

export default Course;
