// index.js
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4"; // Import react-ga4
import "./index.css";

import App from "./App";

// Define GA Measurement IDs
const GA_MEASUREMENT_ID_PROD = "G-MQ9JCS2MFW"; // Replace with your production ID
const GA_MEASUREMENT_ID_DEV = "G-DEV_MEASUREMENT_ID";   // Replace with your development ID if needed

// Function to initialize GA4 based on environment
const initializeGA = () => {
  if (process.env.NODE_ENV === "production") {
    ReactGA.initialize(GA_MEASUREMENT_ID_PROD, {
      gaOptions: {
        anonymize_ip: true, // Anonymize IP addresses for privacy compliance
      },
      debug: false, // Disable debug in production
    });
    console.log("GA Initialized in Production");
  } else {
    console.log("GA Not Initialized (Development Mode)");
    // Optionally, initialize with a development GA ID or skip entirely
    // ReactGA.initialize(GA_MEASUREMENT_ID_DEV, { debug: true });
  }
};

// Initialize GA4
initializeGA();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter basename="/dashboard">
    <App />
  </BrowserRouter>
);